import React from 'react'
import { Frame } from '../frame/frame'
import * as icons from 'react-icons/io'
import { IconContext } from 'react-icons'

import styles from './services.module.scss'
import { replaceMarkdown } from '../../utils/replaceMarkdown'

import { StaticQuery, graphql } from 'gatsby'

const Feature = ({ icon: Icon, title, description }) => (
  <li className={styles.feature}>
    <span className={styles.featureIcon}>
      <Icon />
    </span>
    <h3 className={styles.header}>{title}</h3>
    <p>{description}</p>
  </li>
)

export const Services = () => (
  <StaticQuery
    query={graphql`
      {
        graphCMSData {
          services(orderBy: order_ASC, where: { status: PUBLISHED }) {
            name
            order
            description
            icon
          }
          sectionHeadings {
            type
            text
          }
        }
      }
    `}
    render={data => (
      <Frame
        id="services"
        title="Services"
        description={data.graphCMSData.sectionHeadings
          .filter(heading => heading.type === 'Services')
          .map(heading => (
            <p
              dangerouslySetInnerHTML={{
                __html: replaceMarkdown(heading.text),
              }}
            />
          ))}
      >
        <IconContext.Provider value={{ width: '3rem' }}>
          <ul className={styles.services}>
            {data.graphCMSData.services.map(service => (
              <Feature
                icon={icons['IoMd' + service.icon]}
                title={service.name}
                description={service.description}
              />
            ))}
          </ul>
        </IconContext.Provider>
      </Frame>
    )}
  />
)
